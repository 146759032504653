import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Comments from 'remark-ninja-react';
import 'remark-ninja-react/lib/remark-ninja.css';

import Layout from '../components/layout';
import favicon from '../images/favicon.png';
import {
  article,
  postTitle,
  termHeading,
  termSummary
} from './backend-term.module.css';

const BackendTerm = ({ data }) => {
  const post = data.markdownRemark;
  const site = data.site;
  return (
    <Layout>
      <Helmet
        title={data.site.siteMetadata.title + ': ' + post.frontmatter.title}
        meta={[
          {
            name: 'twitter:card',
            content: 'summary'
          },
          {
            name: 'og:title',
            content: post.frontmatter.title
          },
          {
            name: 'og:description',
            content: site.siteMetadata.title
          },
          {
            name: 'og:image',
            content: site.siteMetadata.url + favicon
          }
        ]}
      />
      <div className={article}>
        <h1 className={postTitle}>{post.frontmatter.title}</h1>
        <h2 className={termHeading}>名词解释</h2>
        <div className={termSummary}>{post.frontmatter.summary}</div>
        <h2 className={termHeading}>LeanCloud 解读</h2>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <h4>评论</h4>
        <Comments
          siteId="a00e39e3-0f72-4070-8818-0b8878b49bc2"
          threadSlug={post.fields.slug}
          pageSize={10}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BackendTermQuery($slug: String!) {
    site {
      siteMetadata {
        title
        url
        domainName
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        summary
      }
      fields {
        slug
      }
    }
  }
`;

export default BackendTerm;
